import React from 'react';

export const Havana = {
    "name": "Havana",
    "link": "havana",
    "country": "Cuba",
    "continent": "North America",
    "thumb": "https://live.staticflickr.com/65535/50379507438_6b75c43e43_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379507438_a69e851c68_o.jpg",
    "caption": "The capital frozen in time.",

    "description": "Havana is the capital city of Cuba. Cuba was a Spanish colony for over 300 years, and you will notice that in the architecture of the city. You will be astonished with its culture, the amazing landmarks and the peculiarity of the city. Take a time here to enjoy a world without Internet and with classic old cars and a classic way of life. The melody of Guantanamera culture fills the streets, with the smoke that evaporates from cigars and exhaust pipes of all the Chevys and rusty Cadillacs present in streets of the city.",

    "nrDays": <p>
        <b>3 days</b> is the recommended time to spend in Havana. It will give you some time to enjoy the beauty of the city and to
        explore all the surroundings. If you want to extend, you can easily make day trips (or more) to Viñales, Trinidad (at least 2 days trip) or Santa Clara
         from here.
    </p>,

    "whenVisit": <p>In our opinion the best time to visit Cuba, and specifically Havana is during the Winter/Spring. From <b>December to
        March</b> you will get a nice weather and less confusion. In the Summer the temperatures in the country are really hot and will
        make you feel exhausted faster.
</p>,
    "mobility": <p>Moving around Havana using the public system is not so easy. There are buses that are <b>exclusive for locals</b> and buses exclusive for tourists.
    <br/><br/>
    The ones used by the tourists are mainly to travel between cities. The best solution is visit the city on foot, since the main attractions are relatively close and when you need to move between two distant points the best solution is to get a <b>taxi</b>.
    The prices practiced by taxis are very high for where you are, and since there is a huge quantity of taxis in Havana, you can bargain your price for the trip.
</p>,
    "safety": <p>Havana is a <b>safe place</b> for tourists. Theft and crime is virtually nonexistent. The biggest problems that you will
        face are the multitude of scams people here create to get some money from tourists.
        None of them will cause any harm to you, it's only the way that some locals have of earning some money from tourists.
        <br/><br/>
        You will have many locals approaching you, and although they mean no harm, most of the times they are trying to bring you to some bar where you spend your money and they get a commission.
        Other times they will try to help you anything and <b>ask for some money</b> in the end. Simply answer politely with a <i>"No Thanks"</i> if they approach you for this and continue your journey.
        <br/><br/>
        Also, <b>never</b> exchange money on the streets, you will either be getting counterfeit money or the national coin <b>CUP</b> worth much less than what you gave.
    </p>,
    "itinerary": {
        "description": "As described before we suggest a 3 day itinerary to see all the hot spots of the city. The day trips to Viñales and Trinidad will not be included on this one. ",
        "days": [
            {
                "description": <p>
                    On your first day start with a visit the UNESCO Heritage site of <b>Old Town Havana</b>. Walk around the streets and
                    admire the buildings, colors and people. To learn more and experience the best of Old Havana, you can do a guided
                    walking tour. During your walk, visit the <b>Old Havana Square or Plaza Vieja</b>, the <b>Catedral de San Cristobal</b>.
                    Get a lunch in a good restaurant at the streets and after that visit the <b>Hotel Ambos Mundos</b>, a famous place due to
                    the influence of the writer Ernest Hemingway. Continue your walk to <b>Plaza de San Francisco de Asís</b> and <b>Hamel Alley</b>.
                    <br/><br/>
                    Next visit the streets of <b>Central Havana</b> until the evening. This is the place where most of the locals live and is not so touristed.
                    This is a good place to know more about the residential area of the city and the day-to-day of the locals. For dinner return to Old Havana and don't forget to drink some <i>Cuba Libres</i> and <i>Mojitos</i>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50488955438_5239823cf3_o.jpg",
                "caption": "Old Havana Streets."
            },
            {
                "description": <p>
                    For the second day take a time to learn about the revolution period. Before that start it with a visit to the <b>José Marti
                Memorial Plaza</b>, located just outside the old town. Steps away from the José Marti Monument you will find the <b>Great Theatre of Havana Alicia Alonso</b>.
                The inside is worthy and it will cost around 5 CUC. After that continue your trip to the most astonishing building in the city, <b>El Capitolio</b>. If you like history you should also visit the <b>Museo de la
                Revolucion</b>, which has an entry fee of 8 CUC.
                <br/><br/>
                Get a quick lunch at El Canchullero, and continue your walk to the <b>Gramma Memorial</b>, and if you are an arts lover you should visit the <b>National Fine Arts Museum of Cuba</b> close to the Memorial.
                Keeping the revolution period theme in mind, go ahead to the <b>Plaza de la Revolution</b> next, one of the most known plazas in the country. To finish
                your evening visit the <b>Nacional Hotel</b> and watch the sunset at the rooftop of the Gran Hotel Manzana Kempinski. Get a dinner at
                the <b>Fabrica de Arte Cubano</b>, and enjoy the night life there.
                </p>,
                "image": "https://live.staticflickr.com/65535/50489813892_3ec1c12bcf_o.jpg",
                "caption": "El Capitolio."
            },
            {
                "description": <p>
                On your third day you will should focus more on the surroundings of the city. Start with a visit to the <b>Port of Havana</b>, and
                get a ferry here to <b>Casablanca</b>. In this area you will have some interesting spots to see, such as <b>La Cabaña de Che Guevara</b>, the <b>Museo Historico Militar</b> and the <b>Faro del Castillo del Morro</b>.
                You cannot return to the city on foot, so we recommend to get a taxi here to the plaza. Don't forget to bargain, the trip shouldn't cost more than 6CUC.
                After getting back to the city centre, take a ride by the <b>Malecon</b>.
                <br/><br/>
				On the afternoon, don't forget to pay a visit to <b>Fusterlandia</b>, a neighborhood turned into public artwork and certainly one of the most unique places in the city.
				<br/><br/>
                To finish your stay at the city enjoy again the streets of Old Havana and finish your day drinking <i>Mojitos</i> at a rooftop bar.
                </p>,
                "image": "https://live.staticflickr.com/65535/50488955428_71d9c603bb_o.jpg",
                "caption": "Malecon Avenue."
            },
        ]
    },

    "highlights": [
        {
            "name": "Old Havana",
            "description": <p>
                <b>Habana Vieja</b>, or Old Havana in English is an area of Havana where the historical old town is located.
                Old Havana is listed as UNESCO World Heritage Site for being one of the most beautiful colonial cities in the world.
                Visit all the plazas here, and take some time to embrace the beauty and simplicity of the streets, the beautiful colonial houses and
                all the museums.
            </p>,
            "image": "https://live.staticflickr.com/65535/50489666396_472ec071d7_o.jpg"
        },
        {
            "name": "El Capitolio",
            "description": <p>
            This is one of the most symbolic places to visit in the city. It was the seat of government in Cuba until 1959.
            The Capitolio was one of the tallest buildings at one point of time and also housed the world’s third-largest indoor statue.
            </p>,
            "image": "https://live.staticflickr.com/65535/50488955333_569c27da2e_o.jpg"
        },
        {
            "name": "Fabrica de Arte Cubano",
            "description": <p>
                This place is both an art gallery and a night club. Today is mainly frequented by youngsters and tourists for
                affordable drinks. Is open from Thursday to Sunday.
            </p>,
            "image": "https://live.staticflickr.com/65535/50489813732_29c045acc2_o.jpg"
        },
        {
            "name": "Malecon",
            "description": <p>
                The Malecon is the Havana's 8km seawall. You can walk and enjoy the views over the sea and the city. It's a good place to relax.
            </p>,
            "image": "https://live.staticflickr.com/65535/50489666321_aced055c51.jpg"
        },
        {
            "name": "Casablanca",
            "description": "Another place full of history. Along the coastal area, there are many forts here that were constructed by the Spaniards to protect their land.",
            "image": "https://live.staticflickr.com/65535/50489813682_6849e5ccfe_o.jpg"
        },
        {
            "name": "Plaza de la Revolucion",
            "description": <p>
                This square is a famous memorial in Havana. It is dominated by the <b>José Marti Memorial</b> on one side and the <b>Che Guevara</b> and <b>Camilo Cienfuegos faces</b> on the other side.
            </p>,
            "image": "https://live.staticflickr.com/65535/50489813647_a14eaaa818_o.jpg"
        },
        {
            "name": "Museo de la Revolucion",
            "description": <p>
                We recommend doing some research and reading up about the Cuban revolution before visiting this museum.
                However, the museum isn’t entirely enlightening on the events of the revolution. It also has an outdoor section with
                cars, tanks, planes and boats.
            </p>,
            "image": "https://live.staticflickr.com/65535/50489666301_6ac997ea6b_o.jpg"
        },
        {
            "name": "Fusterlandia",
            "description": <p>
				Inspired by Gaudi's public works in Barcelona, Cuban artist <b>José Fuster</b> set to decorate his impoverished neighborhood of Jaimatinas, in the outskirts of Havana. What exists today is an artistic paradise land, where tourists and artists join together to see Fuster's work.
            </p>,
            "image": "https://live.staticflickr.com/65535/51535394968_2f538ae400_o.jpg"
        }

    ],

    "food": {
        "description": "",
        "restaurants": [
            {
                "name": "La Bodeguita del Medio",
                "description": "A famous place all over Cuba. With good traditional food and drinks you should go here at least once.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50489666491_f936ce8ca4_o.jpg"
            },
            {
                "name": "D'Next Bar Cafeteria",
                "description": "A fast food styling restaurant. Kind staff and service.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50489666466_f87e798b75_o.jpg"
            },
            {
                "name": "El Canchullero",
                "description": "Our favorite spot in the city. Delicious food, nice staff and a good atmosphere.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50489666471_d04d30d3af_o.jpg"
            },
            {
                "name": "Paladar Los Mercaderes",
                "description": "Beautiful family restaurant with delicious food and great service.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50489666436_e73c21e95c_o.jpg"
            },
            {
                "name": "La Guarida",
                "description": <p>
                    Set in a beautiful, charming building, this "<i>paladar</i>" is probably the most famous in Cuba and a reference in new Cuban Cuisine.
                    Visited by many celebrities along the years, this is also a must visit.
                </p>,
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50488955383_677c7f2db7_o.jpg"
            },
            {
                "name": "Mojito-Mojito",
                "description": "With amazing traditional food and drinks, you can be sure you will feel satisfied after a meal here.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50489666421_d400fa4442_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
            For a more local experience and cheaper prices we highly recommend you stay in "<i>Casas Particulares</i>". You can find a lot of them
            on <a href="https://airbnb.com" target="_blank" rel="noopener noreferrer">AirBnb</a>, and by doing that you're helping the locals.
            <br/><br/>
            However, if you are more of an <b>hotel</b> person, there are also more comfortable and luxurious places where you can stay for your trip. Here some suggestions:
        </p>,
        "budget": [
            {
                "name": "Hotel Caribbean",
                "description": "Featuring a restaurant, bar and views to the city, Hotel Caribbean is set close to the center of Old Havana.",
                "image": "https://live.staticflickr.com/65535/50488955223_7e16363462_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Hotel Inglaterra",
                "description": "At 200m from the Capitolio this place has a perfect location and will be a good choice for your stay.",
                "image": "https://live.staticflickr.com/65535/50489666266_c3c8679cd2_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Iberostar Parque Central",
                "description": "Close to the Inglaterra, this more luxurious and expensive hotel is a great option for a comfortable stay.",
                "image": "https://live.staticflickr.com/65535/50489813577_fc86c39cde_o.jpg",
                "link": "/"
            },
            {
                "name": "Hotel Plaza",
                "description": "In the same square of the other two before, this is the most luxurious of the three. Has an incredible rooftop with a swimming pool.",
                "image": "https://live.staticflickr.com/65535/50488955173_53f4860a87_o.jpg",
                "link": "/"
            }
        ]
    }
}
