import React from 'react';
import CityGuide from '../../../components/guides/CityComponent';
import Footer from '../../../components/FooterComponent';

import { Havana } from '../../../guides/countries/cuba/havana';

function HavanaPage(props) {
    return (
        <div>
            <CityGuide city={ Havana } pathname={ props.location.pathname } urlBack="/travel-guides/north-america" />
            <Footer />
        </div>
    );
}

export default HavanaPage;
